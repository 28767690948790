:root {
	--blue: rgb(95, 150, 213);
}
.shadow-box {
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	width: 80%;
	padding: 60px 40px;
}

.home {
	width: 100%;
	height: fit-content;
	/* border: 1px solid yellow; */
	color: white;
	z-index: 10;
}
.home .header {
	width: 50%;
	border: 0px solid green;
	margin-top: 200px;
	text-align: left;
	padding: 0 40px;
}
.home .header .date {
	font-family: "Exo 2";
	font-style: normal;
	font-weight: 400;
	font-size: 35px;
	line-height: 30px;
	color: rgba(255, 255, 255, 0.6);
}
.home .header .title {
	font-family: "Exo 2";
	font-style: normal;
	font-weight: 400;
	font-size: 50px;
	line-height: 76px;
	text-align: left;
}
.home .header .button {
	margin-top: 50px;
}
.home .header .logos {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 50px;
}

.home .header .logos .item {
	width: 100px;
	height: 100px;
	overflow: hidden;
}
.home .header .logos .item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.home .header .logos .divider {
	width: 2px;
	height: 100px;
	background-color: white;
	margin: 0 10px;
}
.home .lower-body {
	width: 100%;
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
	border: 0px solid red;
	/* height: 500px; */
}

.home .lower-body .aboutus {
	display: flex;

	flex-direction: column;
	margin-left: 5%;

	/* shadow box */
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	width: 90%;
	padding: 60px 40px;
	transition: 0.5s all;
	height: 500px;
}
.home .lower-body .aboutus .title {
	color: white;
	padding-bottom: 5px;
	text-align: left;
	font-size: 40px;
}
.home .lower-body .aboutus .aboutusbody {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 50px;
}

.home .lower-body .aboutus .aboutusbody .leftc {
	width: 20%;
	margin-left: 20px;

	padding: 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border: 0px solid yellow;
}

.home .lower-body .aboutus .aboutusbody .leftc .item {
	padding: 5px 10px;
	margin: 5px;
	background-color: rgba(255, 255, 255, 0);
	border-radius: 5px;
	box-shadow: 5px 5px 0 rgba(0, 0, 0, 0);
	width: 100%;
	font-size: 20px;
	cursor: pointer;
}
.home .lower-body .aboutus .aboutusbody .leftc .item.active {
	background-color: rgba(255, 255, 255, 0.25);
	box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.home .lower-body .aboutus .aboutusbody .rightc {
	color: white;
	/* width: 50%; */
	max-width: 70%;
	border: 0px solid red;
	/* margin: 0 00px 0 100px; */
	margin: auto;
	font-size: 20px;
	line-height: 30px;
}

.home .lower-body .aboutus .aboutusbody .rightc .link {
	text-decoration: underline;
	cursor: pointer;
	transition: 0.25s all;
	margin-top: 20px;
}

.home .lower-body .aboutus .aboutusbody .rightc .link:hover {
	color: var(--blue);
}

.home .lower-body .tagline {
	margin: 200px 0 100px 0;
	color: white;
	border: 0px solid red;
	width: 100%;
	height: auto;
}
.home .lower-body .tagline h2,
.author {
	text-align: center;
	font-size: 45px;
	margin: 5px;
}
.home .lower-body .tagline .concept {
	margin-top: 50px;
}
.home .lower-body .tagline .author {
	font-size: 25px;
}
.home .lower-body .divimage1 {
	width: 100%;
	position: relative;
	height: 700px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	/* justify-content: flex-end; */
}
.home .lower-body .divimage1 .img {
	height: 700px;
	width: 100%;
	position: absolute;
	border: 0px solid yellow;
	overflow: hidden;
}
.home .lower-body .divimage1 .img img {
	width: 100%;
	object-fit: cover;
	/* object-position: center; */
	/* position: absolute; */
	min-height: 100%;
	min-width: 100%;
}

.home .lower-body .divimage1 .img-tag {
	margin-top: 300px;

	/* shadow box */
	background: rgba(0, 0, 0, 0.7);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	width: 40%;
	padding: 60px 40px;
}

.home .lower-body .gallery-grid {
	width: 90%;
	margin-left: 5%;
	margin-top: 100px;
}

.home .lower-body .gallery-grid p {
	width: 90%;
	margin-bottom: 50px;
	font-size: 40px;
}
.home .lower-body .gallery-grid .grid-container {
	align-items: center;
	width: 100%;
	display: grid;
	grid:
		"L L S1 S2" 1fr
		"L L S3 S4" 1fr
		"S5 S6 L2 L2" 1fr
		"S7 S8 L2 L2" 1fr
		/ 1fr 1fr 1fr 1fr;
	gap: 10px;
}

.home .lower-body .gallery-grid .grid-container div {
	overflow: hidden;
	max-height: 250px;
}

.home .lower-body .gallery-grid .grid-container .L {
	grid-area: L;
	border: 0px solid yellow;
	max-height: 510px;
}
.home .lower-body .gallery-grid .grid-container .S1 {
	grid-area: S1;
}
.home .lower-body .gallery-grid .grid-container .S2 {
	grid-area: S2;
}
.home .lower-body .gallery-grid .grid-container .S3 {
	grid-area: S3;
}
.home .lower-body .gallery-grid .grid-container .S4 {
	grid-area: S4;
}
.home .lower-body .gallery-grid .grid-container .S5 {
	grid-area: S5;
}
.home .lower-body .gallery-grid .grid-container .S6 {
	grid-area: S6;
}
.home .lower-body .gallery-grid .grid-container .L2 {
	grid-area: L2;
	max-height: 510px;
}
.home .lower-body .gallery-grid .grid-container .S7 {
	grid-area: S7;
}
.home .lower-body .gallery-grid .grid-container .S8 {
	grid-area: S8;
}
.home .lower-body .gallery-grid .grid-container img {
	width: 100%;
	object-fit: cover;
	object-position: center;
	/* position: absolute; */
	min-height: 100%;
	min-width: 100%;
}

.home .lower-body .tagline-2 {
	margin: 200px 0;
	color: white;
	border: 0px solid red;
	width: 100%;
	height: auto;

	/*shadow box*/
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);

	padding: 60px 40px;
}
.home .lower-body .tagline-2 h2,
.author {
	text-align: center;
	font-size: 45px;
	margin: 5px;
}
.home .lower-body .tagline-2 .author {
	font-size: 25px;
}

/*events page start*/

.events .header {
	text-align: center;
	width: 100%;
	border: 0px solid yellow;
	color: white;
	margin-top: 50vh;
	transform: translateY(-50%);
	font-weight: 400;
	font-size: 60px;
	line-height: 76px;
	transition: 0.5s all;
}
.events .lower-body {
	margin: 400px 60px;
}
.events .lower-body .search-bar {
	height: 50px;
	width: 80%;
	max-width: 750px;
	min-width: 320px;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	margin: auto;
	border-radius: 25px;
	border: 1px solid rgba(255, 255, 255, 0.25);
}
.events .lower-body .search-bar input {
	height: 50px;
	border-radius: 25px;
	border: none;
	width: 90%;
	background: none;
	padding: 0 20px;
	color: white;
}
.events .lower-body .search-bar input:focus-visible {
	border: 0px;
	outline: none;
}
.events .lower-body .search-results {
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
}
.events .lower-body .tabs {
	display: flex;
	flex-direction: row;
	margin: 20px;
}
.events .lower-body .tabs .item {
	padding: 5px 10px;
	margin: 5px;
	background-color: rgba(255, 255, 255, 0);
	border-radius: 5px;
	box-shadow: 5px 5px 0 rgba(0, 0, 0, 0);
	/* width: 80%; */
	font-size: 20px;
	cursor: pointer;
	color: white;
}
.events .lower-body .tabs .item.active {
	background-color: rgba(255, 255, 255, 0.25);
	box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.events .lower-body .extra {
	padding: 20px;
	background: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(20px);

	-webkit-backdrop-filter: blur(20px);
	width: calc(100% - 40px);
	margin: auto;
	border-radius: 5px;
	color: white;
	font-size: 20px;
	margin: 0 20px;

	display: flex;
	flex-direction: row;
	align-items: center;
}
.events .lower-body .search-results .search-card {
	display: flex;

	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	/* shadow box */
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(20px);

	-webkit-backdrop-filter: blur(20px);
	width: 30%;
	padding: 60px 40px;
	color: white;
	margin: 20px;
	height: 600px;
}
.events .lower-body .search-results .search-card .image-container {
	display: flex;
	overflow: hidden;
	width: 100%;
	height: fit-content;
	height: 180px;
}
.events .lower-body .search-results .search-card .image-container img {
	width: 100%;
	height: auto;
	object-fit: contain;
	object-position: center;
}

.events .lower-body .search-results .search-card .card-text .text {
	font-size: 20px;
	line-height: 30px;
	margin-top: 00px;
	text-overflow: ellipsis;
	height: 180px;
	max-height: 180px;

	overflow: hidden;
}
.events .lower-body .search-results .search-card .card-text .text-head {
	text-align: center;
	font-size: 30px;
	padding: 10px;
}
.events .lower-body .search-results .search-card .bottom {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 20px;
	justify-content: space-evenly;
	width: 100%;
}
.events .lower-body .search-results .search-card .bottom .button {
	font-size: 18px;
	font-weight: 400;
}
.events .lower-body .search-results .search-card .bottom .link {
	text-decoration: underline;
	cursor: pointer;
	transition: 0.25s all;
	color: rgb(208, 208, 208);
	font-size: 18px;
}
.events .lower-body .search-results .search-card .bottom .link:hover {
	color: var(--blue);
}
.events .lower-body .schedule {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 20px;
}
.events .lower-body .schedule .menu {
	width: 30%;
	max-width: 300px;
	max-height: 500px;
	overflow-y: scroll;
	background-color: rgba(255, 255, 255, 0.1);
	color: white;
	padding: 60px 40px;
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
}
.events .lower-body .schedule .menu .item {
	padding: 5px 10px;
	margin: 5px;
	background-color: rgba(255, 255, 255, 0);
	border-radius: 5px;
	box-shadow: 5px 5px 0 rgba(0, 0, 0, 0);
	width: 80%;
	font-size: 20px;
	cursor: pointer;
}
.events .lower-body .schedule .menu .item.active {
	background-color: rgba(255, 255, 255, 0.25);
	box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.events .lower-body .schedule .results {
	width: 60%;
	background-color: rgba(255, 255, 255, 0.1);
	padding: 60px 40px;
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
}
.events .lower-body .schedule .results .search-card {
	width: 80%;
	height: fit-content;
	padding: 30px;
	width: 100%;
}
.events .lower-body .schedule .results .search-card .details {
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* border: 1px solid green; */
	width: 100%;
	gap: 20px;
}
.events .lower-body .tagline-2 {
	margin: 200px 0;
	color: white;
	border: 0px solid red;
	width: 100%;
	height: auto;

	/*shadow box*/
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);

	padding: 60px 40px;
}
.events .lower-body .tagline-2 h2,
.author {
	text-align: center;
	font-size: 45px;
	margin: 5px;
}
.events .lower-body .tagline-2 .author {
	font-size: 25px;
}

.events .link {
	text-decoration: underline;
	cursor: pointer;
	transition: 0.25s all;
	color: rgb(225, 225, 225);
	font-size: 18px;
}
.events .link:hover {
	color: var(--blue);
	text-decoration: underline;
}

/*for singular events - event page*/
.event .header {
	text-align: center;
	width: 100%;
	border: 0px solid yellow;
	color: white;
	margin-top: 60vh;
	transform: translateY(-50%);
	font-size: xx-large;
}
.event .lower-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 15vh;
}
.event .lower-body .desc {
	align-items: center;
	align-self: center;
	/*shadow box*/
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);
	width: 80%;
	padding: 60px 40px;
}

.event .lower-body .desc .title {
	color: white;
	padding-bottom: 20px;
	text-align: left;
	font-size: 40px;
}
.event .lower-body .desc .img {
	width: 100%;
	overflow: hidden;
}
.event .lower-body .desc .img img {
	width: 100%;
	object-fit: cover;
	object-position: center;
}
.event .lower-body .desc .desc-body {
	color: white;
	margin-top: 20px;
	font-size: 20px;
	line-height: 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	
}
.event .link {
	text-decoration: underline;
	cursor: pointer;
	transition: 0.25s all;
	color: rgb(208, 208, 208);
	font-size: 18px;
}

.event .lower-body .tagline-2 {
	margin: 200px 0;
	color: white;
	border: 0px solid red;
	width: 100%;
	height: auto;

	/*shadow box*/
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);

	padding: 60px 40px;
}
.event .lower-body .tagline-2 h2,
.author {
	text-align: center;
	font-size: 45px;
	margin: 5px;
}
.event .lower-body .tagline-2 .author {
	font-size: 25px;
}

.team .header {
	text-align: center;
	width: 100%;
	border: 0px solid yellow;
	color: white;
	margin-top: 50vh;
	margin-left: 200px;
	transform: translateY(-50%);
	font-weight: 400;
	font-size: 60px;
	line-height: 76px;
	transition: 0.5s all;
}
.team .lower-body {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	margin-top: 300px;
}
.team .lower-body .menu {
	width: 30%;
	max-width: 300px;
	/* max-height: 500px; */
	/* overflow-y: scroll; */
	background-color: rgba(255, 255, 255, 0.1);
	color: white;
	padding: 60px 40px;
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
}
.team .lower-body .menu .item {
	padding: 5px 10px;
	margin: 5px;
	background-color: rgba(255, 255, 255, 0);
	border-radius: 5px;
	box-shadow: 5px 5px 0 rgba(0, 0, 0, 0);
	width: 80%;
	font-size: 20px;
	cursor: pointer;
}
.team .lower-body .menu .item.active {
	background-color: rgba(255, 255, 255, 0.25);
	box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.team .lower-body .results {
	width: 60%;
	background-color: rgba(255, 255, 255, 0.1);
	padding: 60px 40px;
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
}
.team .lower-body .results .title {
	color: white;
	padding-bottom: 5px;
	text-align: left;
	font-size: 40px;
}
.team .lower-body .results .members {
	display: flex;
	flex-flow: wrap;
	align-items: center;
	justify-content: space-evenly;
	margin-top: 20px;
}
.team .lower-body .results .members .member {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin: 20px;
	text-align: center;
	color: white;
	/* border: 1px solid red; */
}
.team .lower-body .results .members .member .img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.1);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* border: 1px solid red; */
}
.team .lower-body .results .members .member .img .circle {
	width: 180px;
	height: 180px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	z-index: 1;
	/* border: 1px solid red; */
}
.team .lower-body .results .members .member .img .dp {
	width: 150px;
	height: 150px;
	z-index: 2;
	border-radius: 50%;
	overflow: hidden;
}
.team .lower-body .results .members .member .img .dp img {
	width: 100%;
	min-width: 100%;
	min-height: 100%;
	object-position: center;
	object-fit: cover;
	border-radius: 50%;
}
.team .lower-body .results .members .member .position {
	color: rgb(208, 208, 208, 0.75);
}

.sponsors .header {
	text-align: center;
	width: 100%;
	border: 0px solid yellow;
	color: white;
	margin-top: 40vh;
	transform: translateY(-50%);
	font-weight: 400;
	font-size: 60px;
	line-height: 76px;
	transition: 0.5s all;
}
.sponsors .lower-body {
	margin-top: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.sponsors .lower-body .desc {
	align-items: center;
	align-self: center;
	/*shadow box*/
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);
	width: 80%;
	padding: 60px 40px;
}
.sponsors .lower-body .desc .title {
	color: white;
	padding-bottom: 5px;
	text-align: left;
	font-size: 40px;
}
.sponsors .lower-body .desc .desc-body {
	color: white;
	margin-top: 20px;
	font-size: 20px;
	line-height: 30px;
}
.sponsors .lower-body .desc .desc-body .link {
	margin-top: 20px;
	text-decoration: underline;
}
.sponsors .lower-body .present {
	align-items: center;
	align-self: center;
	/*shadow box*/
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);
	width: 80%;
	padding: 60px 40px;
	margin-top: 100px;
}
.sponsors .lower-body .past {
	align-items: center;
	align-self: center;
	/*shadow box*/
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);
	width: 80%;
	padding: 60px 40px;
	margin-top: 100px;
}
.sponsors .lower-body .partners {
	align-items: center;
	align-self: center;
	/*shadow box*/
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);
	width: 80%;
	padding: 60px 40px;
	margin-top: 100px;
}
.sponsors .lower-body .present .title {
	color: white;
	padding-bottom: 5px;
	text-align: left;
	font-size: 40px;
}
.sponsors .lower-body .past .title {
	color: white;
	padding-bottom: 5px;
	text-align: left;
	font-size: 40px;
}
.sponsors .lower-body .partners .title {
	color: white;
	padding-bottom: 5px;
	text-align: left;
	font-size: 40px;
}
.sponsors .lower-body .present .items {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	margin-top: 20px;
	justify-content: space-evenly;
}
.sponsors .lower-body .past .items {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	margin-top: 20px;
	justify-content: space-evenly;
}
.sponsors .lower-body .partners .items {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	margin-top: 20px;
	justify-content: space-evenly;
}
.sponsors .lower-body .present .items .sponsor {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin: 20px;
	text-align: center;
	color: white;
}
.sponsors .lower-body .past .items .sponsor {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin: 20px;
	text-align: center;
	color: white;
}
.sponsors .lower-body .partners .items .sponsor {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin: 20px;
	text-align: center;
	color: white;
}
.sponsors .lower-body .present .items .sponsor .img {
	/* width: 200px; */
	height: 200px;
	border-radius: 100px;
	/* background: rgba(0, 0, 0, 0.1); */
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.sponsors .lower-body .past .items .sponsor .img {
	/* width: 200px; */
	height: 200px;
	border-radius: 100px;
	/* background: rgba(0, 0, 0, 0.1); */
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.sponsors .lower-body .partners .items .sponsor .img {
	/* width: 200px; */
	height: 200px;
	border-radius: 100px;
	/* background: rgba(0, 0, 0, 0.1); */
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.sponsors .lower-body .present .items .sponsor .img .dp {
	/* width: 150px; */
	height: 120px;
	z-index: 2;
	/* border-radius: 50%; */
}
.sponsors .lower-body .past .items .sponsor .img .dp {
	/* width: 120px; */
	height: 120px;
	z-index: 2;
	/* border-radius: 50%; */
}
.sponsors .lower-body .partners .items .sponsor .img .dp {
	/* width: 120px; */
	height: 120px;
	z-index: 2;
	/* border-radius: 50%; */
}

.register .header {
	text-align: center;
	width: 100%;
	border: 0px solid yellow;
	color: white;
	margin-top: 40vh;
	transform: translateY(-50%);
	font-weight: 400;
	font-size: 60px;
	line-height: 76px;
	transition: 0.5s all;
}
.register .lower-body {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.register .lower-body .form {
	align-items: center;
	align-self: center;
	/*shadow box*/
	background: rgba(0, 0, 0);
	/* backdrop-filter: blur(40px); */
	/* -webkit-backdrop-filter: blur(20px); */
	width: 60%;
	padding: 60px 40px;
	border-radius: 5px;
	border: 1px solid rgb(208, 208, 208, 0.75);
}
.register .lower-body .form .title {
	color: white;
	padding-bottom: 5px;
	text-align: left;
	font-size: 40px;
	margin-bottom: 40px;
}
.register .lower-body .form .inputs {
	display: flex;
	flex-flow: row wrap;
	gap: 20px;
}
.register .lower-body .form .button {
	margin-top: 40px;
}

.footer {
	background: rgba(0, 0, 0);
	width: 90%;
	margin: auto;
	margin-top: 200px;
	padding: 60px 40px;
	border-radius: 5px;
	border: 1px solid rgb(208, 208, 208, 0.75);
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	color: white;
	margin-bottom: 20px;
}
.footer .left-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 30%;
}
.footer .right-col {
	width: 30%;
}
.footer .left-col .logo {
	width: 100px;
}
.footer .left-col .logo img {
	width: 100%;
	height: 100%;
}
.footer .left-col .name {
	color: white;
	font-weight: 600;
	/* color: white !important; */
	font-size: 30px;
}
.footer .left-col .logos {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 20px;
}

.footer .left-col .logos .item {
	width: 80px;
	height: 80px;
	overflow: hidden;
}
.footer .left-col .logos .item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.footer .left-col .logos .divider {
	width: 2px;
	height: 80px;
	background-color: white;
	margin: 0 10px;
}
.footer .left-col .address {
	color: rgb(208, 208, 208, 0.75);
	font-size: 15px;
	width: 100%;
	margin: auto;
	margin-top: 10px;
}
.footer .right-col .title,
.footer .right-col-2 .title {
	font-size: 20px;
	margin-left: 0px;
	padding: 10px 0;
}
.footer .right-col .contacts {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-flow: column nowrap;
	/* border: 1px solid red; */
}
.footer .right-col .contacts .contact {
	width: 100%;
	padding: 10px;
	/* border: 1px solid red; */
}
.footer .right-col .contacts .contact .mail {
	color: rgba(255, 255, 255, 0.75);
}
.footer .right-col .contacts .contact .ph-num {
	color: rgba(255, 255, 255, 0.75);
}
.footer .right-col .contacts .contact .mail a {
	color: rgba(255, 255, 255, 0.75);
	text-decoration: none;
}
.footer .right-col .contacts .contact3 .mail {
	color: rgba(255, 255, 255, 0.75);
}
.footer .right-col .contacts .contact3 .mail a {
	color: rgba(255, 255, 255, 0.75);
	text-decoration: none;
}
.footer .right-col-2 .socials {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-flow: column nowrap;
}
.footer .right-col-2 .socials .item {
	display: flex;
	flex-direction: row;
	padding: 10px;
}
.footer .right-col-2 .socials .item .logo {
	margin: 0 10px 0 0;
}

.contact .header {
	text-align: center;
	width: 100%;
	border: 0px solid yellow;
	color: white;
	margin-top: 60vh;
	transform: translateY(-50%);
	font-weight: 400;
	font-size: 60px;
	line-height: 76px;
	transition: 0.5s all;
}
.contact .lower-body {
	margin: auto;
	margin-top: 100px;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-evenly;

	color: white;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);
	width: 80%;
	padding: 60px 40px;
}
.contact .lower-body .items .title {
	font-size: 25px;
	font-weight: 600;
	margin: 20px 0;
}
.contact .lower-body .items .item {
	padding: 10px 0;
}
.contact .lower-body .items .item .name {
	font-weight: 600;
	margin-bottom: 5px;
	font-size: 18px;
}
.contact .lower-body .socials .item {
	display: flex;
	flex-direction: row;
	padding: 10px;
}
.contact .lower-body .socials .item .logo {
	margin: 0 10px 0 0;
}

.foryou .header {
	text-align: center;
	width: 100%;
	border: 0px solid yellow;
	color: white;
	margin-top: 60vh;
	transform: translateY(-50%);
	font-weight: 400;
	font-size: 60px;
	line-height: 76px;
	transition: 0.5s all;
}
.foryou .lower-body {
	margin: auto;
	margin-top: 100px;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-evenly;

	width: 100%;
}
.foryou .lower-body .item {
	width: 45%;
	color: white;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);
	/* width: 80%; */
	/* padding: 60px 40px; */
}
.foryou .lower-body .item .img {
	width: 100%;
	overflow: hidden;
}
.foryou .lower-body .item .img img {
	width: 100%;
}
.foryou .lower-body .item .body {
	padding: 20px 40px;
	font-size: 20px;
	line-height: 30px;
	margin-top: 20px;
}
.foryou .lower-body .item .body .button {
	margin-top: 20px;
}

.well-wishers .header {
	text-align: center;
	width: 100%;
	border: 0px solid yellow;
	color: white;
	margin-top: 60vh;
	transform: translateY(-50%);
	font-weight: 400;
	font-size: 60px;
	line-height: 76px;
	transition: 0.5s all;
}
.well-wishers .lower-body {
	margin: auto;
	margin-top: 100px;

	/* display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-evenly; */

	color: white;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(20px);
	width: 80%;
	padding: 60px 40px;
	font-size: 20px;
}
.well-wishers .lower-body ul {
	list-style: decimal;
	margin: 0 20px;
}
.well-wishers .lower-body ul li {
	margin: 10px 0;
}

@media only screen and (max-width: 480px),
	only screen and (max-device-width: 480px) {
	.header {
		font-size: xx-large !important;
		margin: auto !important;
		margin-top: calc(50px + 50vw) !important;
		transform: none;
	}
	.home .header {
		text-align: center;
		width: 100%;
		margin-top: 150px !important;
	}
	.home .header .title {
		text-align: center;
		font-size: 35px;
		margin-top: 0px;
	}
	.home .header .date {
		text-align: center;
		font-size: 20px;
		/* width: 30%; */
		margin: auto;
		font-weight: 600;
	}
	.home .header .button {
		margin: auto;
		margin-top: 20px;
	}
	.home .header .logos {
		justify-content: center;
	}
	.home .lower-body .aboutus {
		padding: 30px 20px;
		height: fit-content;
	}
	.home .lower-body .aboutus .aboutusbody {
		flex-direction: column;
		margin-top: 20px;
	}
	.home .lower-body .aboutus .aboutusbody .leftc {
		width: 100%;
		margin-left: 0;
		flex-direction: row;
	}
	.home .lower-body .aboutus .aboutusbody .leftc .item {
		width: fit-content;
		font-size: 15px;
		text-align: center;
	}
	.home .lower-body .aboutus .aboutusbody .rightc {
		max-width: 100%;
		width: 100%;
		font-size: 15px;
	}
	.home .lower-body .gallery-grid .grid-container {
		align-items: center;
		width: 100%;
		display: grid;
		grid:
			"L L" 1fr
			"L L" 1fr
			"S5 S6" 1fr
			"S7 S8" 1fr
			"L2 L2" 1fr
			"L2 L2" 1fr
			"S1 S2" 1fr
			"S3 S4" 1fr
			/ 1fr 1fr;
		gap: 10px;
	}

	.events .lower-body {
		margin: 200px 20px;
	}
	.events .lower-body .search-results .search-card {
		margin: 20px 10px;
		width: calc(100% - 20px);
		padding: 30px 30px;
	}
	.events .lower-body .search-results .search-card .card-text .text {
		font-size: 15px;
	}
	.events .lower-body .search-results .search-card .card-text .text-head {
		font-size: 25px;
		margin-top: 20px;
	}
	.events .lower-body .search-results .search-card .bottom {
		justify-content: space-around;
	}
	.events .lower-body .schedule {
		flex-direction: row;
	}
	.events .lower-body .schedule .menu {
		padding: 10px;
	}
	.events .lower-body .schedule .menu .item {
		font-size: 15px;
		margin: 5px 0;
		width: 100%;
	}
	.team .lower-body {
		margin-top: 200px;
		flex-direction: column;
	}
	.team .lower-body .results {
		margin: 20px;
		padding: 30px 20px;
		width: calc(100% - 40px);
		margin-top: 20px;
	}
	/* .team .lower-body .results .members .member .img{
		width: 150px;
		height: 150px;
	} */
	.team .lower-body .team-dropdown{
		/* width: 100%; */
		margin: auto;
		color: white !important;
	}
	.team .lower-body .menu {
		padding: 10px;
	}
	.team .lower-body .menu .item {
		font-size: 15px;
		margin: 5px 0;
		width: 100%;
	}
	.events .lower-body .schedule {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 20px;
}
.events .lower-body .schedule .menu{
	display: flex;
	flex-direction: row;
	width: 100%;
}
	.events .lower-body .schedule .results {
		padding: 10px;
		width: 100%;
	}

	.events .lower-body .schedule .results .search-card {
		width: 100%;
		height: fit-content;
		padding: 10px;
	}
	.events .lower-body .schedule .results .search-card .text-head {
		font-size: 20px;
		margin-top: 0;
	}
	.events .lower-body .schedule .results .search-card .details {
		display: flex;
		flex-direction: column;
	}
	.team .lower-body .results .title {
		font-size: 25px;
	}
	.team .lower-body .results .members .member .img .circle {
		animation: none !important;
		width: 180px !important;
		height: 180px;
		margin-top: 0 !important;
	}

	.sponsors .lower-body {
		margin-top: 200px;
	}
	.sponsors .lower-body .desc {
		padding: 30px;
	}
	.sponsors .lower-body .desc .title,
	.sponsors .lower-body .present .title,
	.sponsors .lower-body .past .title {
		font-size: 25px;
	}
	.sponsors .lower-body .desc .desc-body {
		font-size: 15px;
	}
	.sponsors .lower-body .present,
	.sponsors .lower-body .past {
		padding: 30px;
	}
	.sponsors .lower-body .present .items .sponsor,
	.sponsors .lower-body .past .items .sponsor {
		margin: 10px 0;
	}
	.sponsors .lower-body .present .items .sponsor .img,
	.sponsors .lower-body .past .items .sponsor .img {
		width: 120px;
		height: 120px;
	}
	.sponsors .lower-body .present .items .sponsor .img .dp,
	.sponsors .lower-body .past .items .sponsor .img .dp {
		width: 100px;
		height: 100px;
	}

	.contact .lower-body {
		flex-direction: column;
		padding: 30px;
		gap: 20px;
	}

	.footer {
		flex-direction: column;
	}
	.footer .left-col {
		flex-direction: column;
		width: 100%;
	}
	.footer .right-col,
	.footer .right-col-2 {
		width: 90%;
		margin-top: 10px;
	}
	.footer .right-col .contacts .contact {
		width: 100%;
	}
	.footer .right-col-2 .socials {
		justify-content: flex-start;
	}

	.register .lower-body .form {
		padding: 30px;
		width: 80%;
	}
	.register .lower-body .form .title {
		font-size: 25px;
	}

	.foryou .lower-body {
		flex-direction: column;
		margin-top: 200px;
	}
	.foryou .lower-body .item {
		width: 90%;
		margin: auto;
		margin-bottom: 20px;
	}
	.foryou .lower-body .item .body {
		padding: 10px 30px;
		font-size: 15px;
	}
}
