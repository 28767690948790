.App {

	overflow-x: hidden;
	width: 100%;
	max-width: 100%;
	overflow-y: hidden;
	height: fit-content;
	border: 0px solid red;
	position: relative;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	z-index: -1;
	border: 0px solid green;
}
.bg img{
	width: 100%;
	min-width: 100%;
	height: 100%;
	min-height: 100%;
	padding: 0;
	margin: 0;
	
	position: relative;
	z-index: 0;
	/* margin-top: -10px; */
	object-fit: fill;
	transition: 0.5s all;
	
}
.bg img::after{
	content: "";
	position: absolute;
	height: 450px;
	width: 100%;
	bottom: -50%;
	left: 0;
	/* border: 1px solid yellow; */
	background: rgba(255, 255, 255, 0.005);
	/* backdrop-filter: blur(100px); */
	/* -webkit-backdrop-filter: blur(100px); */
}
::-webkit-scrollbar {
	width: 5px;
	background-color: transparent;
	margin-left: 5px;
	padding: 5px;
	border: 0px solid red;
}
::-webkit-scrollbar-thumb{
	background-color: rgba(255, 255, 255, 0.258);
  /* filter: hue-rotate(120deg); */
	border-radius: 25px;
  /* mix-blend-mode: color-burn; */
}
::-webkit-scrollbar-thumb:hover {
	background-color: rgba(255, 255, 255, 0.46);
}